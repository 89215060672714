import { AnalyticsBrowser } from '@segment/analytics-next'
import Analytics from 'analytics-node'

type Traits = {
  name?: string
  email?: string
  userLoginId?: string
}

type Properties = {
  id?: string
  path?: string
  referrer?: string
}

const segmentKey = process.env.SERVER_SEGMENT_WRITE_KEY
const publicSegmentKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY

// Initialize Server Analytics using analytics-node
const analyticsServer = segmentKey ? new Analytics(segmentKey) : null

let analyticsClient: AnalyticsBrowser | null = null

if (typeof window !== 'undefined' && publicSegmentKey) {
  // Initialize Client Analytics using @segment/analytics-next
  analyticsClient = AnalyticsBrowser.load({
    writeKey: publicSegmentKey,
  })
}

const trackEvent = (
  anonymousId: string,
  event: string,
  properties: Properties,
  traits: Traits
) => {
  const analytics =
    typeof window !== 'undefined' ? analyticsClient : analyticsServer
  if (analytics instanceof Analytics) {
    analytics.track({ anonymousId, event, properties, context: { traits } })
  } else if (analytics instanceof AnalyticsBrowser) {
    analytics.track(event, properties, { context: { traits } })
  }
}

const identifyUser = (anonymousId: string, traits: Traits) => {
  const analytics =
    typeof window !== 'undefined' ? analyticsClient : analyticsServer
  if (analytics instanceof Analytics) {
    analytics.identify({ anonymousId, traits: traits })
  } else if (analytics instanceof AnalyticsBrowser) {
    analytics.identify(anonymousId, {
      context: {
        traits,
      },
    })
  }
}

const trackPageView = (anonymousId: string, traits: Traits) => {
  if (analyticsClient) {
    // Always use client-side tracking for page view
    analyticsClient.page(
      anonymousId,
      {},
      {
        context: {
          traits,
        },
      }
    )
  }
}

export const analytics = {
  trackEvent,
  identifyUser,
  trackPageView,
}
