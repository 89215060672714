'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useSession } from 'next-auth/react'

import { analytics } from '@/lib/segment'
import { hashUserLoginId } from '@/lib/utils'

interface ClientTrackPageViewsProps {
  anonymousId: string
}

const ClientTrackPageViews = ({ anonymousId }: ClientTrackPageViewsProps) => {
  const pathname = usePathname() || ''
  const { data: session, status } = useSession()
  const { user } = session || {}

  useEffect(() => {
    const trackPage = async () => {
      if (status === 'loading') return
      if (session) {
        analytics.trackPageView(anonymousId, {
          email: user?.email || '',
          name: user?.name || '',
          userLoginId: user && hashUserLoginId(user),
        })
      } else {
        analytics.trackPageView(anonymousId, {})
      }
    }
    trackPage()
  }, [session, status, pathname, anonymousId, user])

  return null
}

export default ClientTrackPageViews
