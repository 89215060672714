'use client'

import React from 'react'
import { useRouter } from 'next/navigation'

import { Button } from '@/components/ui/button'

import { DropdownMenuItem } from './ui/dropdown-menu'

export const LoginButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => {
  const router = useRouter()

  return (
    <Button
      role="link"
      ref={ref}
      variant="primary"
      onClick={() => router.push('/sign-in')}
      className={className}
      {...props}
    >
      {children || 'Log in'}
    </Button>
  )
})
LoginButton.displayName = 'LoginButton'

export const LoginLink = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => {
  const router = useRouter()

  return (
    <Button
      ref={ref}
      role="link"
      variant="primary-breadcrumb-link"
      onClick={() => router.push('/sign-in')}
      className={className}
      {...props}
    >
      {children || 'Log in'}
    </Button>
  )
})
LoginLink.displayName = 'LoginLink'

export const RegisterButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, ...props }, ref) => {
  const router = useRouter()

  const handleRegister = () => {
    router.push('/register')
  }

  return (
    <Button
      ref={ref}
      role="link"
      variant="primary-outline"
      className={className}
      onClick={handleRegister}
      {...props}
    >
      {children || 'Register'}
    </Button>
  )
})
RegisterButton.displayName = 'RegisterButton'

export const LogoutButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    inDropdown?: boolean
  }
>(({ className, children, inDropdown = false, ...props }, ref) => {
  const router = useRouter()

  const buttonContent = (
    <Button
      ref={ref}
      variant="primary-outline"
      onClick={() => router.push('/sign-out')}
      className={className}
      {...props}
    >
      {children || 'Log out'}
    </Button>
  )

  if (inDropdown) {
    return <DropdownMenuItem asChild>{buttonContent}</DropdownMenuItem>
  }

  return buttonContent
})
LogoutButton.displayName = 'LogoutButton'
