'use client'

import * as React from 'react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { cva, type VariantProps } from 'class-variance-authority'
import { User } from 'lucide-react'

import { cn } from '@/lib/utils'

const avatarVariants = cva(
  'relative flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-accent ring-1 ring-secondary-form-border',
  {
    variants: {
      size: {
        'x-24': 'size-6',
        'x-32': 'size-7',
        'x-40': 'size-8',
        'x-48': 'size-9',
        'x-64': 'size-10',
      },
    },
    defaultVariants: {
      size: 'x-40',
    },
  }
)

const avatarIconVariants = cva('absolute fill-current', {
  variants: {
    size: {
      'x-24': 'size-3.5',
      'x-32': 'size-4',
      'x-40': 'size-5',
      'x-48': 'size-6',
      'x-64': 'size-7',
    },
  },
  defaultVariants: {
    size: 'x-40',
  },
})

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {
  media?: 'image' | 'icon'
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, media = 'image', size, ...props }, ref) => {
  return (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(avatarVariants({ size, className }))}
      {...props}
    >
      {media === 'icon' && (
        <User className={cn(avatarIconVariants({ size }))} />
      )}
    </AvatarPrimitive.Root>
  )
})
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('size-full rounded-full', className)}
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      'flex size-full items-center justify-center rounded-full bg-muted',
      className
    )}
    {...props}
  />
))
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarFallback, AvatarImage }
