'use client'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'

export default function SkipToMainContent() {
  const skipToMain = (e) => {
    e.preventDefault()
    const firstH1 = document.querySelector('h1')
    if (firstH1) {
      firstH1.setAttribute('tabindex', '-1')
      firstH1.scrollIntoView({ behavior: 'auto' })
      firstH1.focus()
      firstH1.removeAttribute('tabindex')
    }
  }

  return (
    <button
      onClick={skipToMain}
      className={cn(
        'absolute -top-2 left-4 size-1 overflow-hidden bg-background px-1 text-base focus:size-auto md:left-5',
        buttonVariants({ variant: 'secondary-link' })
      )}
      type="button"
    >
      Skip to main content
    </button>
  )
}
