import { clsx, type ClassValue } from 'clsx'
import { sha256 } from 'js-sha256'
import { User } from 'next-auth'
import { twMerge } from 'tailwind-merge'

import { Vendor, Wellness } from '@/lib/types/entitlements'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function exists<T>(value: T): value is NonNullable<T> {
  return Boolean(value)
}

export function checkBenefitsEligibility(wellness: Wellness | null): boolean {
  return !!wellness?.benefits?.length
}

export function filterByPartner(hasBenefits: boolean, entitlements: Wellness) {
  if (!hasBenefits) return undefined
  const hasUprise = entitlements?.benefits.some(
    (item) => item.vendor === Vendor.UpriseHealth
  )
  const hasCompsych = entitlements?.benefits.some(
    (item) => item.vendor === Vendor.ComPsych
  )
  return hasUprise && !hasCompsych ? Vendor.ComPsych : undefined
}

export function sortBySubcategory(subCategoryList: string[] | undefined) {
  return subCategoryList?.sort((a, b) =>
    a
      .split(':')[1]
      ?.trim()
      .toLowerCase()
      .localeCompare(b.split(':')[1]?.trim().toLowerCase())
  )
}
export function removeDuplicateSubcategories(
  subCategories?: string[],
  primaryCategory?: string
): string[] {
  const uniqueSubcategoriesMap = new Map<string, string>()

  subCategories?.forEach((subCategory) => {
    const [category, subCategoryValue] = subCategory
      .split(':')
      .map((str) => str.trim())

    // If the subcategory doesn't exist or primaryCategory is more relevant, set it
    if (
      !uniqueSubcategoriesMap.has(subCategoryValue) ||
      category === primaryCategory
    ) {
      uniqueSubcategoriesMap.set(subCategoryValue, subCategory)
    }
  })

  return Array.from(uniqueSubcategoriesMap.values())
}

export function hashUserLoginId(user: User) {
  return user! && sha256(`${user.name}-${user.email}`)
}
